import React, {useEffect, useState} from "react";
import APIClient, {CustomerMemory, KnowledgeBind} from "../../api";
import dayjs from "dayjs";
import {useList} from "react-use";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Chip,
  Input,
  Modal,
  ModalContent,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure
} from "@nextui-org/react";
import {useParams} from "react-router-dom";
import {Tooltip} from "@nextui-org/tooltip";
import {CiEdit} from "react-icons/ci";
import {MdAccountCircle, MdDeleteOutline} from "react-icons/md";
import {wait} from "../../utils/waiter";
import {GoPlus} from "react-icons/go";
import {BiMemoryCard} from "react-icons/bi";
import {SlBookOpen} from "react-icons/sl";

export default function CustomerDetail() {

  const params = useParams();
  const customerId = params.customerId!;
  return (
    <div className="h-full flex *:flex-1 space-x-4">
      <div className="h-full">
        <KnowledgeBindList customerId={customerId}/>
      </div>
      <div className="h-full">
        <CustomerMemoryList customerId={customerId}/>
      </div>
    </div>

  )
}

function KnowledgeBindList({customerId}) {
  const columns = [
    {key: "knowledgeBaseName", name: "知识库名称"},
    {key: "accountName", name: "厂商名称"},
    {key: "createAt", name: "绑定时间"}
  ]
  const [knowledgeBaseBindList, setKnowledgeBaseBindList] = useList<KnowledgeBind>();

  useEffect(() => {
    (async () => {
      const list = await APIClient.customer.knowledgeBaseBindList(customerId);
      setKnowledgeBaseBindList.set(list)
    })()
  }, [setKnowledgeBaseBindList, customerId]);

  const renderKnowledgeCell = React.useCallback((knowledgeBind: KnowledgeBind, columnKey: React.Key) => {
    switch (columnKey) {
      case "knowledgeBaseName":
        return (
          <div>{knowledgeBind.knowledgeBaseName}</div>
        );
      case "accountName":
        return (
          <div>{knowledgeBind.accountName}</div>
        );
      case "createAt":
        return (<div>{dayjs(knowledgeBind.createAt).format("YYYY-MM-DD HH:mm")}</div>)
      default:
        return <div>...</div>;
    }
  }, []);

  const topContent = React.useMemo(() => {
    return (
      <div className="flex flex-col gap-4">
        <div className="flex justify-between gap-3 items-end">
          <Chip startContent={<SlBookOpen size={18}/>}
                variant="bordered" color="secondary" size="md"
          >用户已绑知识库列表</Chip>
        </div>
      </div>
    );
  }, []);


  return (
    <>
      <Table aria-label="知识库绑定列表" topContent={topContent}>
        <TableHeader columns={columns}>
          {(column) => (
            <TableColumn key={column.key} align={column.key === "actions" ? "center" : "start"}>
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody items={knowledgeBaseBindList}>
          {(item) => (
            <TableRow key={item.id}>
              {(columnKey) => <TableCell>{renderKnowledgeCell(item, columnKey)}</TableCell>}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  )
}


function CustomerMemoryList({customerId}) {
  const [timestamp, setTimestamp] = useState(new Date().getTime());
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const [customerMemory, setCustomerMemory] = useState<CustomerMemory | undefined>(undefined)
  const columns = [
    {key: "customerMemory", name: "客户画像"},
    {key: "createAt", name: "创建时间"},
    {key: "actions", name: "操作"}
  ]
  const [customerMemoryList, setCustomerMemoryList] = useList<CustomerMemory>();
  const renderKnowledgeCell = React.useCallback((customerMemory: CustomerMemory, columnKey: React.Key) => {
    switch (columnKey) {
      case "customerMemory":
        return (
          <div>{customerMemory.content}</div>
        );
      case "createAt":
        return (<div>{dayjs(customerMemory.createAt).format("YYYY-MM-DD HH:mm")}</div>)
      case "actions":
        return (
          <div className="relative flex items-center gap-2">
            <Tooltip color="primary" content="修改">
              <span className="text-lg text-primary-400 cursor-pointer active:opacity-50">
                <CiEdit onClick={() => {
                  setCustomerMemory(customerMemory)
                  onOpen()
                }}/>
              </span>
            </Tooltip>
            <Tooltip color="danger" content="删除">
              <span className="text-lg text-danger cursor-pointer active:opacity-50">
                <MdDeleteOutline onClick={() => handleDeleteMemory(customerMemory.id)}/>
              </span>
            </Tooltip>
          </div>
        )
      default:
        return <div>...</div>;
    }
  }, [onOpen]);

  const topContent = React.useMemo(() => {
    return (
      <div className="flex flex-col gap-4">
        <div className="flex justify-between gap-3 items-end">
          <Chip startContent={<BiMemoryCard size={18}/>}
                variant="bordered" color="success" size="md"
          >用户记忆列表</Chip>
          <div className="flex gap-3">
            <Button size="sm" color="primary" endContent={<GoPlus/>} onClick={
              () => {
                setCustomerMemory(undefined);
                onOpen();
              }
            }>
              新增记忆
            </Button>
          </div>
        </div>
      </div>
    );
  }, [
    onOpen
  ]);

  useEffect(() => {
    (async () => {
      const list = await APIClient.customer.MemoryList(customerId);
      setCustomerMemoryList.set(list)
    })()
  }, [setCustomerMemoryList, customerId, timestamp]);


  async function handleDeleteMemory(memoryId: string) {
    const waiter = wait();
    try {
      await APIClient.customer.deleteMemory(memoryId)
      await waiter
    } finally {
      await waiter
      setTimestamp(new Date().getTime());
    }
  }

  return (
    <>
      <Modal shouldBlockScroll={false} placement="center" size="sm"
             isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {
            (onClose) => <MemoryCard memory={customerMemory} onClose={() => {
              onClose();
              setTimestamp(new Date().getTime());
            }}/>
          }
        </ModalContent>
      </Modal>
      <Table aria-label="客户画像列表" topContent={topContent}>
        <TableHeader columns={columns}>
          {(column) => (
            <TableColumn key={column.key} align={column.key === "actions" ? "center" : "start"}>
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody items={customerMemoryList}>
          {(item) => (
            <TableRow key={item.id}>
              {(columnKey) => <TableCell>{renderKnowledgeCell(item, columnKey)}</TableCell>}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  )
}

function MemoryCard({memory, onClose}: {
  memory: CustomerMemory | undefined
  onClose: () => void
}) {
  const [content, setContent] = useState(memory?.content ?? '')
  const action = memory?.id ? '修改' : '新增';
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const customerId = params.customerId!;

  async function handleUpsertMemory(memoryId: string, content: string, customerId: string) {
    const waiter = wait();
    try {
      await APIClient.customer.upsertMemory(memoryId, content, customerId)
      await waiter
    } finally {
      await waiter
      setIsLoading(true)
      onClose()
    }
  }

  return (
    <Card className="w-full">
      <CardHeader className="p-5 flex justify-between">
        <div className="text-medium font-bold">{action}记忆</div>
      </CardHeader>
      <CardBody className="p-5">
        <div className="flex items-center space-x-4">
          <MdAccountCircle size={38}/>
          <Input size="sm" label="记忆内容" isRequired
                 value={content} onValueChange={(content) => {
            setContent(content)
          }}/>
        </div>
      </CardBody>
      <CardFooter className="p-5 space-x-2 flex justify-end">
        <Button size="sm" color="primary" isLoading={isLoading}
                onClick={() => handleUpsertMemory(memory?.id ?? '', content, customerId)}>
          {action}{isLoading ? '中' : ''}
        </Button>
        <Button size="sm" color="danger" onClick={onClose}>取消</Button>
      </CardFooter>
    </Card>
  )
}
