import React, {useEffect, useState} from "react";
import APIClient, {User} from "../../api";
import {useList} from "react-use";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Input,
  Modal,
  ModalContent,
  Spacer,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure
} from "@nextui-org/react";
import {Tooltip} from "@nextui-org/tooltip";
import {FaSearch} from "react-icons/fa";
import dayjs from "dayjs";
import {CiEdit} from "react-icons/ci";
import {MdAccountCircle, MdDeleteOutline, MdOutlineDriveFileRenameOutline} from "react-icons/md";
import {GoPlus} from "react-icons/go";
import {wait} from "../../utils/waiter";
import {TbPasswordUser} from "react-icons/tb";
import {IoIosPhonePortrait} from "react-icons/io";
import {useDebounce} from "ahooks";

export default function AccountList() {

  const [accountList, setAccountList] = useList<User>();
  const [timestamp, setTimestamp] = useState(new Date().getTime());
  const [keyword, setKeyword] = useState('');
  const debouncedKeyword = useDebounce(keyword, {wait: 1500})

  useEffect(() => {
    (async () => {
      const accountList = await APIClient.user.list(debouncedKeyword);
      setAccountList.set(accountList)
    })()
  }, [setAccountList, timestamp, debouncedKeyword]);

  async function handleDelete(account: User) {
    const waiter = wait();
    try {
      await APIClient.user.del(account.id);
      await waiter;
    } finally {
      await waiter;
      setTimestamp(new Date().getTime());
    }
  }

  const columns = [
    {key: "id", name: "商户ID"},
    {key: "username", name: "商户名称"},
    {key: "phone", name: "联系方式"},
    {key: "createAt", name: "创建时间"},
    {key: "updateAt", name: "更新时间"},
    {key: "actions", name: "操作"}
  ]

  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const [currentAccount, setCurrentAccount] = useState<User | undefined>(undefined)

  const topContent = React.useMemo(() => {
    return (
      <div className="flex flex-col gap-4">
        <div className="flex justify-between gap-3 items-end">
          <Input variant="bordered" size="sm"
                 labelPlacement={"outside-left"}
                 placeholder="根据商户名称搜索"
                 isClearable
                 startContent={<FaSearch/>}
                 className="max-w-xs"
                 value={keyword}
                 onValueChange={setKeyword}
                 onClear={() => setKeyword('')}
          />
          <div className="flex gap-3">
            <Button color="primary" endContent={<GoPlus/>} onClick={
              () => {
                setCurrentAccount(undefined);
                onOpen();
              }
            }>
              新增商户
            </Button>
          </div>
        </div>
      </div>
    );
  }, [
    onOpen,
    keyword,
    setKeyword,
  ]);

  const renderCell = React.useCallback((user: User, columnKey: React.Key) => {
    switch (columnKey) {
      case "id":
        return (
          <div>{user.id}</div>
        );
      case "username":
        return (
          <div>{user.username}</div>
        );
      case "phone":
        return (
          <div>{user.phone}</div>
        );
      case "createAt":
        return (<div>{dayjs(user.createAt).format("YYYY-MM-DD HH:mm")}</div>)
      case "updateAt":
        return (<div>{dayjs(user.updateAt).format("YYYY-MM-DD HH:mm")}</div>)
      case "actions":
        return (
          <div className="relative flex items-center gap-2">
            <Tooltip content="编辑">
              <span className="text-lg text-default-400 cursor-pointer active:opacity-50">
                <CiEdit onClick={() => {
                  setCurrentAccount(user);
                  onOpen();
                }}/>
              </span>
            </Tooltip>
            <Tooltip color="danger" content="删除">
              <span className="text-lg text-danger cursor-pointer active:opacity-50">
                <MdDeleteOutline onClick={() => handleDelete(user)}/>
              </span>
            </Tooltip>
          </div>
        );
      default:
        return <div>...</div>;
    }
  }, [onOpen]);

  return (
    <>
      <Modal shouldBlockScroll={false} placement="center" size="sm"
             isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {
            (onClose) => <NewAccountBlock account={currentAccount} onClose={() => {
              onClose();
              setTimestamp(new Date().getTime());
            }}/>
          }
        </ModalContent>
      </Modal>
      <Table aria-label="用户列表"
             topContent={topContent}
      >
        <TableHeader columns={columns}>
          {(column) => (
            <TableColumn key={column.key} align={column.key === "actions" ? "center" : "start"}>
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody items={accountList}>
          {(item) => (
            <TableRow key={item.id}>
              {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  )
}

function NewAccountBlock({account, onClose}: {
  account: User | undefined,
  onClose: () => void
}) {
  const [name, setName] = useState(account?.name ?? '');
  const [password, setPassword] = useState(account?.password ?? '');
  const [username, setUsername] = useState(account?.username ?? '');
  const [phone, setPhone] = useState(account?.phone ?? '');

  const [isLoading, setIsLoading] = useState(false);

  const action = account?.id ? '修改' : '创建';

  async function handleSave() {
    const waiter = wait();
    setIsLoading(true)
    try {
      await APIClient.user.upsert({
        id: account?.id ?? '',
        name: name,
        password: password,
        username: username,
        phone: phone,
      });
      await waiter;
    } finally {
      await waiter;
      setIsLoading(false);
      onClose();
    }
  }

  const [isInvalidName, setIsInvalidName] = useState(false);
  const [isInvalidPassword, setIsInvalidPassword] = useState(false);
  const [isInvalidUsername, setIsInvalidUsername] = useState(false);
  const [isInvalidPhone, setIsInvalidPhone] = useState(false);


  return (
    <Card className="w-full">
      <CardHeader className="p-5 flex justify-between">
        <div className="text-medium font-bold">{action}商户</div>
      </CardHeader>
      <CardBody className="p-5">
        <div className="flex items-center space-x-4">
          <MdAccountCircle size={38}/>
          <Input size="sm" label="昵称" isRequired
                 isInvalid={isInvalidName}
                 errorMessage={isInvalidName ? "请输入账号" : ""}
                 value={name} onValueChange={(name) => {
            if (name === "") {
              setIsInvalidName(true)
            } else {
              setIsInvalidName(false)
            }
            setName(name)
          }}/>
        </div>
        <Spacer y={3}/>
        <div className="flex items-center space-x-4">
          <MdOutlineDriveFileRenameOutline size={38}/>
          <Input size="sm" label="账号" isRequired
                 isInvalid={isInvalidUsername}
                 errorMessage={isInvalidUsername ? "请输入昵称" : ""}
                 value={username} onValueChange={(username) => {
            if (username === "") {
              setIsInvalidUsername(true)
            } else {
              setIsInvalidUsername(false)
            }
            setUsername(username)
          }}/>
        </div>
        <Spacer y={3}/>
        <div className="flex items-center space-x-4">
          <TbPasswordUser size={38}/>
          <Input size="sm" label="密码" isRequired
                 isInvalid={isInvalidPassword}
                 errorMessage={isInvalidPassword ? "请输入密码" : ""}
                 value={password} onValueChange={(password) => {
            if (password === "") {
              setIsInvalidPassword(true)
            } else {
              setIsInvalidPassword(false)
            }
            setPassword(password)
          }}/>
        </div>
        <Spacer y={3}/>
        <div className="flex items-center space-x-4">
          <IoIosPhonePortrait size={38}/>
          <Input size="sm" label="手机号" isRequired
                 isInvalid={isInvalidPhone}
                 errorMessage={isInvalidPhone ? "请输入手机号" : ""}
                 value={phone} onValueChange={(phone) => {
            if (phone === "") {
              setIsInvalidPhone(true)
            } else {
              setIsInvalidPhone(false)
            }
            setPhone(phone)
          }}/>
        </div>
      </CardBody>
      <CardFooter className="p-5 space-x-2 flex justify-end">
        <Button size="sm" color="primary" isLoading={isLoading} onClick={handleSave}
                isDisabled={name === "" || password === "" || username === "" || phone === ""}>
          {action}{isLoading ? '中' : ''}
        </Button>
        <Button size="sm" color="danger" onClick={onClose}>取消</Button>
      </CardFooter>
    </Card>
  );
}
