import React, {useEffect} from "react";
import APIClient, {CustomerModel} from "../../api";
import {useList} from "react-use";
import {Table, TableBody, TableCell, TableColumn, TableHeader, TableRow} from "@nextui-org/react";
import {FaRegEye} from "react-icons/fa";
import dayjs from "dayjs";
import {Tooltip} from "@nextui-org/tooltip";
import {useNavigate} from "react-router-dom";

export default function Customer() {
  const [customerList, setCustomerList] = useList<CustomerModel>();
  const navigate = useNavigate();
  const columns = [
    {key: "name", name: "用户名称"},
    {key: "createAt", name: "注册时间"},
    {key: "actions", name: "操作"}
  ]
  const renderCell = React.useCallback((customer: CustomerModel, columnKey: React.Key) => {
    switch (columnKey) {
      case "name":
        return (
          <div>{customer.name}</div>
        );
      case "createAt":
        return (<div>{dayjs(customer.createAt).format("YYYY-MM-DD HH:mm")}</div>)
      case "actions":
        return (
          <div className="relative flex items-center gap-2">
            <Tooltip content="查看详情">
              <span className="text-lg text-default-400 cursor-pointer active:opacity-50">
                <FaRegEye onClick={() => {
                  navigate(`/console/customer/${customer.id}/detail`)
                }}/>
              </span>
            </Tooltip>
          </div>
        );
      default:
        return <div>...</div>;
    }
  }, [navigate]);

  useEffect(() => {
    (async () => {
      const accountList = await APIClient.customer.list();
      setCustomerList.set(accountList)
    })()
  }, [setCustomerList]);

  return (
    <>
      <Table aria-label="用户列表">
        <TableHeader columns={columns}>
          {(column) => (
            <TableColumn key={column.key} align={column.key === "actions" ? "center" : "start"}>
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody items={customerList}>
          {(item) => (
            <TableRow key={item.id}>
              {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  )
}
