import React, {MutableRefObject, useEffect, useRef, useState} from "react";
import {useList} from "react-use";
import APIClient, {KnowledgeBase, KnowledgeBaseType, KnowledgeBaseUpsert} from "../../api";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Input,
  Modal,
  ModalContent,
  Select,
  SelectItem,
  Spacer,
  useDisclosure
} from "@nextui-org/react";
import {useNavigate} from "react-router-dom";
import Emoji from "../../components/Emoji";
import {SlOptions} from "react-icons/sl";
import {wait} from "../../utils/waiter";
import {FaPlus, FaQrcode} from "react-icons/fa";
import {HiOutlineIdentification} from "react-icons/hi";
import {CgDatabase} from "react-icons/cg";
import Loading from "../../components/Loading";
import {QRCode} from "react-qrcode-logo";

function KnowledgeBaseBindBlock({knowledgeBase, onClose}: { knowledgeBase: KnowledgeBase, onClose: () => void }) {
  const [loading, setLoading] = useState<boolean>(true);
  const [url, setURL] = useState<string | null>(null);
  const qrcodeRef = useRef<QRCode>()
  useEffect(() => {
    (async () => {
      const waiter = wait();
      try {
        setLoading(true);
        const url = await APIClient.knowledgeBase.bindQRCode(knowledgeBase.id)
        await waiter;
        setURL(url);
      } finally {
        await waiter;
        setLoading(false);
      }
    })();
  }, [knowledgeBase])
  return (
    <Card>
      <CardHeader className="px-5 flex justify-center font-bold">客户激活码</CardHeader>
      <CardBody className="p-5 h-[200px] flex justify-center items-center">
        {loading && <Loading/>}
        {url && <QRCode
          ref={qrcodeRef as MutableRefObject<QRCode>}
          quietZone={0}
          ecLevel={"L"}
          eyeRadius={150}
          qrStyle="dots"
          value={url}
        />}
      </CardBody>
      <CardFooter className="px-5 flex justify-center space-x-2">
        <Button
          color="primary" size="sm"
          onClick={() => qrcodeRef.current?.download("png", `${knowledgeBase.name} - 客户激活码`)}
        >下载</Button>
        <Button size="sm" onClick={onClose}>关闭</Button>
      </CardFooter>
    </Card>
  )
}

function KnowledgeBaseBlock({knowledgeBase, onDelete, onEdit}: {
  knowledgeBase: KnowledgeBase,
  onDelete: () => void,
  onEdit: () => void
}) {
  const navigate = useNavigate();

  const [isDeleting, setIsDeleting] = useState(false);

  async function handleDelete() {
    const waiter = wait();
    try {
      setIsDeleting(true);
      await APIClient.knowledgeBase.del(knowledgeBase.id)
      await waiter;
      onDelete();
    } finally {
      await waiter;
      setIsDeleting(false)
    }
  }

  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  return (
    <>
      <Modal placement="center" size="sm" isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => <KnowledgeBaseBindBlock knowledgeBase={knowledgeBase} onClose={onClose}/>}
        </ModalContent>
      </Modal>
      <Card shadow="sm" className="w-full h-48">
        <CardHeader className="p-4 flex justify-between">
          <Emoji icon={knowledgeBase.icon}/>
          <div className="text-sm font-bold">{knowledgeBase.name}</div>
          <div
            className="cursor-pointer p-2 rounded-3xl hover:shadow"
            onClick={onEdit}>
            <SlOptions/>
          </div>
        </CardHeader>
        <CardBody className="p-4"></CardBody>
        <CardFooter className="space-x-2 flex justify-between items-center">
          {
            knowledgeBase.type === KnowledgeBaseType.Private
              ? <Button variant="ghost" color="secondary" size="sm" isIconOnly onClick={() => onOpen()}>
                <FaQrcode size={18}/>
              </Button>
              : <div/>
          }
          <div className="flex items-center space-x-2">
            <Button variant="ghost" color="primary" size="sm"
                    onClick={() => navigate(`/console/knowledge-base/${knowledgeBase.id}/knowledge`)}>管理</Button>
            <Button variant="ghost" isLoading={isDeleting} color="danger" size="sm" onClick={handleDelete}>删除</Button>
          </div>
        </CardFooter>
      </Card>
    </>
  )
}

function NewKnowledgeBaseBlock({knowledgeBase, onClose}: {
  knowledgeBase: KnowledgeBaseUpsert | undefined,
  onClose: () => void
}) {
  const [icon, setIcon] = useState(knowledgeBase?.icon ?? ':book:');
  const [name, setName] = useState(knowledgeBase?.name ?? '');
  const [type, setType] = useState(knowledgeBase?.type ?? KnowledgeBaseType.Public);

  const [isLoading, setIsLoading] = useState(false);

  const action = knowledgeBase?.id ? '修改' : '创建';

  async function handleSave() {
    const waiter = wait();
    setIsLoading(true)
    try {
      await APIClient.knowledgeBase.upsert({name, icon, id: knowledgeBase?.id ?? '', type});
      await waiter;
    } finally {
      await waiter;
      setIsLoading(false);
      onClose();
    }
  }

  return (
    <Card className="w-full">
      <CardHeader className="p-5 flex justify-between">
        <div className="text-medium font-bold">{action}知识库</div>
      </CardHeader>
      <CardBody className="p-5">
        <div className="flex items-center space-x-4">
          <Emoji size={36} picker icon={icon} onIconChanged={setIcon}/>
          <Input size="sm" label="名称" endContent={<HiOutlineIdentification size={22}/>}
                 value={name} onValueChange={setName}/>
        </div>
        <Spacer y={3}/>
        <div className="flex items-center space-x-4">
          <CgDatabase size={38}/>
          <Select placeholder="选择知识库类型" defaultSelectedKeys={[type]} size={"md"} onChange={(e) => {
            setType(e.target.value === KnowledgeBaseType.Public ? KnowledgeBaseType.Public : KnowledgeBaseType.Private)
          }}>
            <SelectItem key="Public">公共知识库</SelectItem>
            <SelectItem key="Private">私有知识库</SelectItem>
          </Select>
        </div>
      </CardBody>
      <CardFooter className="p-5 space-x-2 flex justify-end">
        <Button size="sm" color="primary" isLoading={isLoading} onClick={handleSave}>
          {action}{isLoading ? '中' : ''}
        </Button>
        <Button size="sm" color="danger" onClick={onClose}>取消</Button>
      </CardFooter>
    </Card>
  );
}

export default function KnowledgeBaseList() {
  const [knowledgeBases, setKnowledgeBases] = useList<KnowledgeBase>();
  const [timestamp, setTimestamp] = useState(new Date().getTime());
  useEffect(() => {
    (async () => {
      const knowledgeBases = await APIClient.knowledgeBase.list();
      setKnowledgeBases.set(knowledgeBases);
    })()
  }, [setKnowledgeBases, timestamp])

  const [currentKnowledgeBase, setCurrentKnowledgeBase] = useState<KnowledgeBaseUpsert | undefined>(undefined)
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  return (
    <>
      <Modal placement="center" size="sm" isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {
            (onClose) => <NewKnowledgeBaseBlock knowledgeBase={currentKnowledgeBase} onClose={() => {
              onClose();
              setTimestamp(new Date().getTime());
            }}/>
          }
        </ModalContent>
      </Modal>
      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
        <Card className="w-full h-48 flex justify-center items-center animate__animated animate__fadeIn"
              isPressable isHoverable
              shadow="sm"
              onClick={() => {
                setCurrentKnowledgeBase(undefined);
                onOpen();
              }}>
          <FaPlus size={32}/>
        </Card>
        {
          knowledgeBases.map((it, index) => (
            <div key={it.id}
                 className="animate__animated animate__fadeIn animate__faster"
                 style={{animationDelay: `${index * 50}ms`}}>
              <KnowledgeBaseBlock knowledgeBase={it} onDelete={() => setTimestamp(new Date().getTime())} onEdit={() => {
                setCurrentKnowledgeBase(it);
                onOpen();
              }}/>
            </div>
          ))
        }
      </div>
    </>
  )
}
