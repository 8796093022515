import React, {useEffect, useMemo, useState} from "react";
import APIClient, {Bot} from "../../api";
import {wait} from "../../utils/waiter";
import Loading from "../../components/Loading";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Chip,
  Input,
  Select,
  SelectItem,
  Snippet,
  Spacer,
  Textarea
} from "@nextui-org/react";
import Emoji from "../../components/Emoji";
import {MdPublish} from "react-icons/md";
import {FaCode, FaInfinity} from "react-icons/fa6";
import {RiWechat2Line} from "react-icons/ri";
import styled from "styled-components";

export default function BotEditor() {
  const [bot, setBot] = useState<Bot | undefined>(undefined);
  const [timestamp, setTimestamp] = useState(new Date().getTime())
  useEffect(() => {
    (async () => {
      const waiter = wait();
      const bot = await APIClient.bot.get();
      await waiter;
      setBot(bot || {
        model: "",
        instructions: "",
        appId: "",
        appSecret: "",
        token: "",
        subscribeReply: "",
      });
    })()
  }, [timestamp]);

  async function handleSubmit(bot: Bot) {
    const waiter = wait();
    await APIClient.bot.upsert(bot);
    await waiter;
    setTimestamp(new Date().getTime())
  }

  return (
    <div className="h-full flex *:flex-1 space-x-4">
      <div className="h-full">
        <BotSetting bot={bot} setBot={setBot} onSubmit={handleSubmit}/>
      </div>
      <div className="h-full">
        <BindMp bot={bot} setBot={setBot} onSubmit={handleSubmit}/>
      </div>
    </div>
  )
}

function BotSetting({bot, setBot, onSubmit}: {
  bot: Bot | undefined,
  setBot: React.Dispatch<React.SetStateAction<Bot | undefined>>,
  onSubmit: (bot: Bot) => Promise<void>,
}) {
  const [models, setModels] = useState<string[]>([]);
  useEffect(() => {
    (async () => {
      const models = await APIClient.bot.models();
      setModels(models)
    })()
  }, []);
  const selectedModel = useMemo(() => bot && models.includes(bot.model) ? [bot.model] : [], [models, bot]);
  const [loading, setLoading] = useState(false);

  //加载中
  if (typeof bot === "undefined") return <Loading/>

  return (
    <Card className="h-full w-full flex flex-col">
      <CardHeader className="px-5 flex h-16">
        <div className="flex items-center space-x-4">
          <Emoji size={38} icon=":robot_face:"/>
          <div>
            <div className="text-sm font-bold">机器人</div>
            <div className="text-[12px] max-w-48 whitespace-nowrap overflow-ellipsis overflow-hidden">
              一个很有用的机器人
            </div>
          </div>
        </div>
        <div className="flex-1"></div>
        <Button isLoading={loading}
                endContent={<MdPublish size={18}/>}
                variant="ghost" color="primary" size="sm"
                onClick={async () => {
                  try {
                    setLoading(true);
                    await onSubmit(bot);
                  } finally {
                    setLoading(false);
                  }
                }}>
          发布
        </Button>
      </CardHeader>
      <CardBody className="flex flex-col overflow-hidden">
        <div className="rounded-xl bg-gray-50 p-3 overflow-y-auto">
          <div className="flex *:flex-1 space-x-4">
            <Select size="sm" label="模型" labelPlacement="outside" placeholder="请选择LLM模型"
                    endContent={<FaInfinity className="text-secondary" size={22}/>}
                    selectedKeys={selectedModel}
                    onSelectionChange={(selection) => {
                      selection = selection as Set<string>
                      const selected = Array.from(selection.values()).map(it => it.toString());
                      if (selected.length > 0)
                        setBot({
                          ...bot,
                          model: selected[0]
                        })
                    }}
            >
              {models.map(m => <SelectItem key={m} value={m}>{m}</SelectItem>)}
            </Select>
          </div>
        </div>
        <Spacer y={3}/>
        <TextAreaWrapper className="flex-1">
          <Textarea color="primary" variant="bordered" placeholder="请在此输入AI指令"
                    startContent={<div className="text-primary"><FaCode size={18}/></div>}
                    disableAutosize={true}
                    value={bot.instructions}
                    onValueChange={(v) => setBot({...bot, instructions: v})}
          />
        </TextAreaWrapper>
      </CardBody>
    </Card>
  );
}

function BindMp({bot, setBot, onSubmit}: {
  bot: Bot | undefined,
  setBot: React.Dispatch<React.SetStateAction<Bot | undefined>>,
  onSubmit: (bot: Bot) => Promise<void>,
}) {
  const [loading, setLoading] = useState(false);

  if (bot === undefined) return <Loading/>

  return (
    <Card className="h-full w-full flex flex-col">
      <CardHeader className="px-5 flex h-16">
        <div className="flex items-center space-x-4">
          <Chip startContent={<RiWechat2Line size={18}/>}
                variant="bordered" color="success" size="lg"
          >微信公众号</Chip>
        </div>
        <div className="flex-1"></div>
        <div className="flex items-center space-x-2">
          <Button variant="ghost" color="primary" size="sm" isLoading={loading}
                  onClick={async () => {
                    try {
                      setLoading(true);
                      await onSubmit(bot);
                    } finally {
                      setLoading(false);
                    }
                  }}>
            {loading ? '保存中' : '保存'}
          </Button>
        </div>
      </CardHeader>
      <CardBody className="flex flex-col overflow-hidden">
        <Snippet symbol="🌐" tooltipProps={{content: "服务器地址(URL)"}}>
          {`${window.location.protocol}//${window.location.host}/api/mp`}
        </Snippet>
        <Spacer y={3}/>
        <Input label="AppId" variant="bordered"
               isRequired value={bot.appId}
               onValueChange={(v) => setBot({...bot, appId: v,})}
        />
        <Spacer y={3}/>
        <Input label="AppSercet" variant="bordered"
               isRequired value={bot.appSecret}
               onValueChange={(v) => setBot({...bot, appSecret: v})}
        />
        <Spacer y={3}/>
        <Input label="Token" variant="bordered"
               isRequired value={bot.token}
               onValueChange={(v) => setBot({...bot, token: v})}
        />
        <Spacer y={3}/>
        <TextAreaWrapper className="flex-1">
          <Textarea label="关注回复" variant="bordered"
                    disableAutosize={true}
                    value={bot.subscribeReply}
                    onValueChange={(v) => setBot({...bot, subscribeReply: v})}
          />
        </TextAreaWrapper>
      </CardBody>
    </Card>
  );
}

const TextAreaWrapper = styled.div`
  > div {
    height: 100% !important;

    > div {
      height: 100% !important;

      > div > textarea {
        height: 100% !important;
      }
    }
  }
`;
