import axios, {AxiosError, type AxiosInstance, type AxiosRequestConfig} from "axios";
import {jsonParse} from "../utils/json";
import {APIAuthenticationError, APICommonError, APIError} from "./error";
import {CompletedData, WebSocketEx} from "../utils/websocket";
import {EventStream} from "../utils/event_stream";
import {
  Bot,
  CustomerMemory,
  CustomerModel,
  Knowledge,
  KnowledgeBase,
  KnowledgeBaseUpsert,
  KnowledgeBind,
  UpsertAccount,
  User
} from "./model";
import {getToken} from "../hooks/useToken";

export class Client {
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: "/api",
      transformResponse: (data: any, _, status) => {
        if (status && status <= 400) {
          return jsonParse(data)
        }
        return null
      }
    });
    this.axiosInstance.interceptors.request.use((config: any) => {
      config.headers["TOKEN"] = getToken()
      return config;
    });
  }

  async post<T>(url: string, data?: any, config?: AxiosRequestConfig<any>): Promise<T> {
    try {
      const resp = await this.axiosInstance.post<{ success: boolean, message: string, data: T }>(url, data, config);
      if (resp.data.success) {
        return resp.data.data;
      } else { //通用异常. 直接显示
        throw new APICommonError(resp.data.message);
      }
    } catch (e: any) {
      if (e instanceof AxiosError) {
        if (e?.response?.status === 403) { //跳转登录
          throw new APIAuthenticationError();
        } else {
          throw new APIError(-1, e.message, e);
        }
      } else if (e instanceof APIError) {
        throw e;
      } else {
        throw new APIError(-1, e.message || '未知错误', e);
      }
    }
  }

  tts = async (
    text: string,
    options: {
      voice?: string,
      signal?: AbortSignal
    }
  ) => {
    const resp = await axios.post(
      'api/voice/tts',
      {text, voice: options.voice},
      {
        responseType: 'arraybuffer',
        signal: options.signal
      },
    );
    if (resp.status === 204)
      return new ArrayBuffer(0);
    if (resp.status !== 200)
      throw new Error("无法语音合成,错误状态码:" + resp.status);
    return resp.data as ArrayBuffer;
  };

  stt = async () => {
    //建立websocket连接
    const url = new URL(window.location.href);
    url.protocol = url.protocol === "https:" ? "wss:" : "ws:";
    url.pathname = `api/voice/stt`
    //连接到websocket
    const websocket = await WebSocketEx.connect(url.toString());
    //等待成功
    const initSuccessData = await websocket.recv();
    if (!(initSuccessData instanceof CompletedData))
      throw new Error("语音识别发生错误");
    if (!initSuccessData.success)
      throw new Error("云端识别启动失败");
    //返回websocket
    return websocket;
  }

  chat = (id: string, message: string) => {
    return new EventStream(
      'api/session',
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({id, message}),
      }
    )
  }

  user = {
    login: (username: string, password: string) => this.post<string>("/user/loginByPassword", {username, password}),
    current: () => this.post<User>("/user/current"),
    bindPhone: (phone: string) => this.post(`/user/bindPhone`, {
      phone
    }),
    list: (keyword: string | null) => this.post<User[]>(`/user/list`, {keyword}),
    upsert: (account: UpsertAccount) => this.post(`/user/upsert`, account),
    del: (id: string) => this.post(`/user/delete`, {id: id})
  }

  customer = {
    list: () => this.post<CustomerModel[]>(`/customer/list`),
    knowledgeBaseBindList: (customerId: string) => this.post<KnowledgeBind[]>(`/customer/knowledgeBaseBindList`, {customerId}),
    MemoryList: (customerId: string) => this.post<CustomerMemory[]>(`/customer/MemoryList`, {customerId}),
    deleteMemory: (memoryId: string) => this.post(`/customer/deleteMemoryById`, {memoryId}),
    upsertMemory: (memoryId: string, content: string, customerId: string) => this.post(`/customer/upsertMemoryById`, {
      memoryId,
      content,
      customerId
    })
  }

  knowledgeBase = {
    list: () => this.post<KnowledgeBase[]>("/knowledge-base/list"),
    del: (id: string) => this.post(`/knowledge-base/delete/${id}`),
    upsert: (knowledge: KnowledgeBaseUpsert) => this.post(`/knowledge-base/upsert`, knowledge),
    bindQRCode: (id: string) => this.post<string>(`/knowledge-base/bindQRCode`, {id}),
  }

  knowledge = {
    list: (knowledgeBaseId: string) => this.post<Knowledge[]>(`/knowledge-base/${knowledgeBaseId}/knowledge/list`, {}),
    del: (knowledgeBaseId: string, id: string) => this.post(`/knowledge-base/${knowledgeBaseId}/knowledge/delete/${id}`),
    upsertQA: (knowledgeBaseId: string, question: string, answer: string, id: string) => this.post(`/knowledge-base/${knowledgeBaseId}/knowledge/qa/upsert`, {
      question,
      answer,
      id,
    }),
    updateImageSummary: (knowledgeBaseId: string, id: string, summary: string,) => this.post(`/knowledge-base/${knowledgeBaseId}/knowledge/image/update-summary`, {
      id,
      summary
    }),
    updateMarkdownContent: (knowledgeBaseId: string, id: string, content: string) => this.post(`/knowledge-base/${knowledgeBaseId}/knowledge/markdown/update-content`, {
      id,
      content,
    }),
    summaryImage: (knowledgeBaseId: string, id: string,) => this.post<string>(`/knowledge-base/${knowledgeBaseId}/knowledge/image/summary/${id}`),
    cleanVectoringError: (knowledgeBaseId: string, knowledgeId: string,) => this.post(`/knowledge-base/${knowledgeBaseId}/knowledge/cleanVectoringError`, {knowledgeId}),
  }

  bot = {
    models: () => this.post<string[]>(`/bot/models`),
    list: () => this.post<Bot[]>(`/bot/list`),
    upsert: (bot: Bot) => this.post(`bot/update`, bot),
    get: () => this.post<Bot | null>(`/bot/get`),
  }
}
